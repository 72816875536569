import { graphql } from "gatsby";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageWrapper from "../components/PageWrapper";
import Content1 from "../sections/landing7/Content1";
import Content2 from "../sections/landing7/Content2";
import Content3 from "../sections/landing7/Content3";
import Hero from "../sections/landing7/Hero";
import Rating from "../sections/landing7/Rating";

const Partner = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "left",
          isFluid: true,
          button: "account", // cta, account, null
        }}
      >
        <Header />
        <Hero />
        <Rating />
        <Content1 />
        <Content2 />
        <Content3 />
        <Footer />
      </PageWrapper>
    </>
  );
};
export default Partner;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
